import { extendObservable, decorate, action } from 'mobx';
import AuthAPI from '../services/AuthAPI';
import StorageUtil, { KEYS } from '~/helpers/utils/StorageUtil';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import { redirectTo } from './redirectService';
import LoginModel from '../models/LoginModel';

/**
 * Store que trata toda lógica de autenticação do usuário.
 */
class AuthStore {
  constructor(rootStore, history) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    this.history = history;
    extendObservable(this, {
      isAuthenticated: false,
      loading: false,
      authoritie: false,
      username: '',
      password: '',
      remember: StorageUtil.getItem(KEYS.REMEMBER),
      authentication: undefined,
      code: undefined,
    });

    if (this.remember === 'true') this.autoLogin();
  }

  /**Verifica se os dados salvos na storage são válidos e faz o login automaticamente.*/
  async autoLogin() {
    this.loading = true;
    //busca usuário salvo no localstorage,
    //caso exista verifica se as credenciais ainda são válidas
    const savedUser = StorageUtil.getItem(KEYS.USER_KEY);
    if (savedUser) {
      const result = await this.rootStore.usersStore.get(savedUser);
      this.isAuthenticated = !result.error && true;
      if (!this.isAuthenticated) {
        StorageUtil.cleanAll();
      }
    }
    this.loading = false;
  }

  async updateProp(prop, value) {
    const login = this.authentication ? this.authentication : new LoginModel();
    switch (prop) {
      default:
        login[prop] = value;
        break;
    }
    this.authentication = new LoginModel(login);
  }

  async updateCode(prop, value) {
    this.code = value;
  }


  get isStoomAdmin() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === 'STOOM';
  }

  /**Verifica se a credencial do usuário é de superadmin */
  get isSuperAdmin() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === 'SUPERADMIN';
  }

  /**Verifica se a credencial do usuário é de admin */
  get isAdmin() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === 'ADMIN';
  }

  /** Faz o login */
  async login() {
    const routerPath = process.env.REACT_APP_ROUTE_PATH || ''
    this.loading = true;
    const data = {
      username: this.authentication.username,
      password: this.authentication.password,
    };
    const code = this.code;
    const result = await AuthAPI.login(data, code);
    this.isAuthenticated = !result.error && true;
    if (!this.isAuthenticated) {
      StorageUtil.cleanAll();
      this.toastHelper.notify(STATUS_HELPER.ERROR, result.error);
    } else {
      await this.rootStore.usersStore.get(this.authentication.username);
      StorageUtil.setItem(KEYS.TOKEN_KEY, result.headers.authorization);
      redirectTo(`${routerPath}/administradores`);
      setTimeout(() => {
        window.location.href = window.location.href;
      }, 1000);
    }
    this.loading = false;
    return this.isAuthenticated;
  }

  /** MFA */
  async twoFactorAuthentication() {
    this.loading = true;
    const data = {
      email: this.authentication.username,
      password: this.authentication.password,
      username: this.authentication.username,
      '@class': 'br.com.stoom.kernel.model.persistence.User',
    };
    const result = await AuthAPI.twoFactorAuthentication(data);
    this.loginAuthenticated = !result.error && true;
    if (!result.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'E-mail enviado com sucesso'
      );
      StorageUtil.setItem(KEYS.REMEMBER, true);
    } else {
      StorageUtil.cleanAll();
      this.toastHelper.notify(STATUS_HELPER.ERROR, result.error);
    }
    this.loading = false;
    return this.loginAuthenticated;
  }

  /**Logout */
  async logout() {
    this.loading = true;
    await AuthAPI.logout();
    this.isAuthenticated = false;
    this.loading = false;
    return this.isAuthenticated;
  }

  /**Envia */
  async redefinePass(email) {
    const response = await AuthAPI.redefinePass(email);
    return response;
  }

  async validateToken(token) {
    const response = await AuthAPI.validateToken(token);
    return response;
  }

  async changePassword(customerUUID, token, newPassword) {
    const response = await AuthAPI.changePassword(
      customerUUID,
      token,
      newPassword
    );
    return response;
  }
}

export default AuthStore;

decorate(AuthStore, {
  autoLogin: action,
});
